:root {
  --app-height: 100%;
}

body,
html {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

#root {
  height: 100%;
}
